import React, { createContext, useContext, useState, useRef } from 'react';
import { toast } from 'react-hot-toast';

const Context = createContext();

export const StateContext = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [totalQuantities, setTotalQuantities] = useState(0);
  const [qty, setQty] = useState(1);

  let foundProduct;
  let index;

  const calculatePrice = (product, validityPeriod, domainCount) => {
    if (
      !product ||
      typeof product.price !== 'number' ||
      typeof product.additionalDomainPrice !== 'number' ||
      !Array.isArray(product.validityOptions)
    ) {
      console.error('Invalid product details', product);
      return 0;
    }

    // Ensure validityPeriod and domainCount are numbers
    const numericValidityPeriod = Number(validityPeriod);
    const numericDomainCount = Number(domainCount);

    if (isNaN(numericValidityPeriod) || isNaN(numericDomainCount) || numericDomainCount < 1) {
      console.error('Invalid validityPeriod or domainCount', { validityPeriod, domainCount });
      return 0; // Guard clause for invalid input types or values
    }

    const basePrice = product.price;
    const additionalPrice = product.additionalDomainPrice * Math.max(0, numericDomainCount - 1);

    let totalPriceBeforeDiscount = basePrice + additionalPrice;

    const validityOption = product.validityOptions.find(
      (option) => option.years === numericValidityPeriod
    );

    if (!validityOption) {
      console.error('No matching validity option found', {
        validityPeriod: numericValidityPeriod,
        validityOptions: product.validityOptions,
      });
      return totalPriceBeforeDiscount;
    }

    const multiplier = validityOption.multiplier || 1;
    let totalPrice = totalPriceBeforeDiscount * multiplier;

    return Math.round(totalPrice);
  };

  const onAdd = (product, quantity, validityPeriod, domainCount) => {
    const price = calculatePrice(product, validityPeriod, domainCount) * quantity;

    setTotalPrice((prevTotalPrice) => prevTotalPrice + price);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);

    const existingItemIndex = cartItems.findIndex((item) => item._id === product._id);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      updatedCartItems[existingItemIndex].validityPeriod = validityPeriod;
      updatedCartItems[existingItemIndex].domainCount = domainCount;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...product, quantity, validityPeriod, domainCount }]);
    }

    toast.success(`${quantity} ${product.name} added to the cart.`);
  };

  const onAddDmarc = (product, quantity) => {
    const price = product.annualPrice || product.price * 12;

    setTotalPrice((prevTotalPrice) => prevTotalPrice + price * quantity);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + quantity);

    const existingItemIndex = cartItems.findIndex((item) => item._id === product._id);
    if (existingItemIndex !== -1) {
      const updatedCartItems = [...cartItems];
      updatedCartItems[existingItemIndex].quantity += quantity;
      setCartItems(updatedCartItems);
    } else {
      setCartItems([...cartItems, { ...product, quantity, price }]);
    }

    toast.success(`${quantity} ${product.name} added to the cart.`);
  };

  const onRemove = (product) => {
    const itemToRemove = cartItems.find((item) => item._id === product._id);
    if (!itemToRemove) return;

    const updatedCartItems = cartItems.filter((item) => item._id !== product._id);
    setCartItems(updatedCartItems);

    setTotalPrice((prevTotalPrice) => prevTotalPrice - itemToRemove.price * itemToRemove.quantity);
    setTotalQuantities((prevTotalQuantities) => prevTotalQuantities - itemToRemove.quantity);

    setTotalPrice(0);
    setTotalQuantities(0);
    setCartItems([]);
  };

  const toggleCartItemQuantity = (id, value) => {
    foundProduct = cartItems.find((item) => item._id === id);
    index = cartItems.findIndex((product) => product._id === id);
    const newCartItems = cartItems.filter((item) => item._id !== id);

    if (value === 'inc') {
      setCartItems([...newCartItems, { ...foundProduct, quantity: foundProduct.quantity + 1 }]);
      setTotalPrice((prevTotalPrice) => prevTotalPrice + foundProduct.price);
      setTotalQuantities((prevTotalQuantities) => prevTotalQuantities + 1);
    } else if (value === 'dec') {
      if (foundProduct.quantity > 1) {
        setCartItems([...newCartItems, { ...foundProduct, quantity: foundProduct.quantity - 1 }]);
        setTotalPrice((prevTotalPrice) => prevTotalPrice - foundProduct.price);
        setTotalQuantities((prevTotalQuantities) => prevTotalQuantities - 1);
      }
    }
  };

  const incQty = () => {
    setQty((prevQty) => prevQty + 1);
  };

  const decQty = () => {
    setQty((prevQty) => {
      if (prevQty - 1 < 1) return 1;

      return prevQty - 1;
    });
  };

  const addToCart = (product, quantity, validityPeriod, domainCount) => {
    const totalPrice = calculatePrice(product, validityPeriod, domainCount);
    setCartItems([
      ...cartItems,
      {
        ...product,
        quantity,
        validityPeriod,
        domainCount,
        totalPrice, // Make sure this line is included
      },
    ]);
  };

  return (
    <Context.Provider
      value={{
        showCart,
        setShowCart,
        cartItems,
        totalPrice,
        totalQuantities,
        qty,
        incQty,
        decQty,
        calculatePrice,
        onAdd,
        onAddDmarc,
        toggleCartItemQuantity,
        onRemove,
        setCartItems,
        setTotalPrice,
        setTotalQuantities,
        addToCart,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useStateContext = () => useContext(Context);
