import React, { useState } from 'react';
import jsonp from 'jsonp';

const SubscribeForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const url = `https://brandymail.us14.list-manage.com/subscribe/post-json?u=3b2bfbd9183334a868e822bd1&id=494be30c1d&EMAIL=${encodeURIComponent(
      email
    )}&c=__jp0`;

    jsonp(url, { param: 'c' }, (err, data) => {
      if (err) {
        setMessage('Oops! Something went wrong.');
      } else if (data.result !== 'success') {
        setMessage('Oops! Something went wrong.');
      } else {
        setMessage('Thanks for joining our newsletter!');
        setEmail(''); // Clear the email field on success
      }
    });
  };

  return (
    <div className="inner-container _525px width-100">
      <div className="mg-bottom-24px">
        <div className="text-center---tablet">
          <div className="text-300 bold color-neutral-100">Subscribe to our newsletter</div>
        </div>
      </div>
      <div className="form-button-inside-block w-form">
        <form onSubmit={handleSubmit} className="form-button-inside">
          <input type="hidden" name="form_type" value="customer" />
          <input type="hidden" name="utf8" value="✓" />

          <input
            aria-label="Email Address"
            type="email"
            className="input button-inside w-input"
            maxLength="256"
            name="EMAIL"
            data-name="Email"
            placeholder="Enter your email"
            id="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="submit"
            className="btn-primary inside-input default w-button"
            value="Subscribe"
          />
        </form>
        {message && <div className="message color-neutral-600">{message}</div>}
      </div>
    </div>
  );
};

export default SubscribeForm;
