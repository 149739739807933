import React from 'react';
import styles from './HeaderSkeleton.module.scss';

const HeaderSkeleton = () => (
  <div className={`header-wrapper w-nav ${styles.skeleton}`}>
    <div className="container-default w-container">
      <div className={`header-content-wrapper ${styles.headerContentWrapper}`}>
        {/* Logo placeholder */}
        <div className={styles.skeletonItem} style={{ width: '150px', height: '40px' }}></div>

        {/* Navigation links placeholder */}
        <div style={{ display: 'flex', gap: '20px' }}>
          {[1, 2, 3, 4].map((item) => (
            <div
              key={item}
              className={styles.skeletonItem}
              style={{ width: '80px', height: '20px' }}
            ></div>
          ))}
        </div>

        {/* Right side elements placeholder */}
        <div style={{ display: 'flex', gap: '15px', alignItems: 'center' }}>
          <div
            className={styles.skeletonItem}
            style={{ width: '24px', height: '24px', borderRadius: '50%' }}
          ></div>
          <div
            className={styles.skeletonItem}
            style={{ width: '24px', height: '24px', borderRadius: '50%' }}
          ></div>
          <div className={styles.skeletonItem} style={{ width: '100px', height: '36px' }}></div>
        </div>
      </div>
    </div>
  </div>
);

export default HeaderSkeleton;
